import { appLayoutContext } from "layout/app/layout-context";
import { SideMenuLink } from "layout/app/side-menu/side-menu-link";
import React, { useEffect } from "react";
import { agencyContext } from "../agency-pages/agencies-context";
import { propertyContext } from "./property-context";

const PropertyBusinessPage = () => {
  const { useStore } = appLayoutContext;
  const updateNavigationBar = useStore((state) => state.updateNavigationBar);

  const { useStore: useAgencyStore } = agencyContext;
  const { id: agency_id } = useAgencyStore((state) => state.agency);

  const { useStore: usePropertyStore } = propertyContext;
  const { id: property_id } = usePropertyStore(
    (state) => state.values.property
  );

  useEffect(() => {
    const businessPath = `/app/agencies/${agency_id}/properties/${property_id}/business`;
    const business_navigate = (
      <SideMenuLink label={"Negociações"} linkTo={businessPath} />
    );
    updateNavigationBar(business_navigate, 3);
  }, []);

  return <>PropertyBusinessPage</>;
};

export default PropertyBusinessPage;
