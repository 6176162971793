import {
  IconCalendar,
  IconDashboard,
  IconHome,
  IconReport,
  IconSettings,
  IconUser,
} from "@tabler/icons-react";
import Card, { CardWithSections } from "components/basic/card/card";
import CardHeader from "components/basic/card/card-header";
import { CardSection } from "components/basic/card/card-section";
import { FlexColumn, FlexRow } from "components/basic/flex";
import Text from "components/basic/text";
import { BiMessageAltX } from "react-icons/bi";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { AgenciesService } from "services/requests";
import { DefaultIcon } from "components/basic/icon-default";
import { ToolTipButton } from "components/basic/tooltip-button";
import { LoadingPage } from "pages/other/loading-page";
import { globalErrorContext } from "context/error-context";
import { appLayoutContext } from "layout/app/layout-context";

export const RealStates = () => {
  const navigate = useNavigate();

  const { useStore: useLayoutContext } = appLayoutContext;
  const { updatePopupProps } = useLayoutContext((state) => state);

  const [agencies, setAgencies] = useState<Array<Record<string, any>>>([]);
  const [isLoading, setIsLoading] = useState(true);

  const { useStore } = globalErrorContext;
  const { setGlobalError } = useStore((state) => state);

  useEffect(() => {
    AgenciesService.getAllAgencies()
      .then((response) => {
        setAgencies(response);
      })
      .catch((error) => {
        setGlobalError(error, navigate, updatePopupProps);
      })
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <FlexRow sx={{ gap: 2, flexWrap: "wrap" }}>
      {agencies.length !== 0 ? (
        agencies.map((agency) => (
          <RealStateCard key={agency.id} agency={agency} />
        ))
      ) : (
        <NotFoundAgenciesCard />
      )}
    </FlexRow>
  );
};

const links = {
  dashboard: { label: "Dashboard", icon: <IconDashboard /> },
  properties: { label: "Properties", icon: <IconHome /> },
  customers: { label: "Customers", icon: <IconUser /> },
  calendar: { label: "Calendar", icon: <IconCalendar /> },
  reports: { label: "Reports", icon: <IconReport /> },
  settings: { label: "Settings", icon: <IconSettings /> },
};

type RealStateCardProps = {
  agency: { [key: string]: any }; //TODO Colocar tipo definitivo dos dados da agência
};
export const RealStateCard: Component<RealStateCardProps> = ({ agency }) => {
  const navigate = useNavigate();
  return (
    <CardWithSections
      size="micro"
      onClick={() => navigate(agency?.id.toString())}
      sx={{
        flexGrow: 1,
        cursor: "pointer",
        transition: "transform 0.2s",
        mb: "2px",
        border: "1px solid transparent",
        "&:hover": {
          background: "neutral.n1",
          transform: "scale(1.02)",
          borderColor: "primary.main",
        },
      }}
    >
      <CardHeader>
        <FlexRow sx={{ gap: 1, p: 1 }}>
          <DefaultIcon text={agency.name[0]} />
          <FlexColumn sx={{ height: "100%", justifyContent: "center" }}>
            <Text sx={{ fontWeight: 600, whiteSpace: "nowrap" }}>
              {agency.name}
            </Text>
            <Text sx={{ color: "text.faded" }}>{agency.id}</Text>
          </FlexColumn>
        </FlexRow>
      </CardHeader>

      <CardSection sx={{ justifyContent: "center" }}>
        {Object.entries(links).map(([key, { label, icon }]) => (
          <ToolTipButton key={key} href={`${agency.id}/${key}`} tooltip={label}>
            {icon}
          </ToolTipButton>
        ))}
      </CardSection>
    </CardWithSections>
  );
};

export const NotFoundAgenciesCard: Component = () => {
  return (
    <Card
      variant="outlined"
      sx={{
        width: "100%",
        background: "neutral.n2",
        justifyContent: "center",
      }}
    >
      <Text
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
        }}
      >
        <BiMessageAltX size={30} />
        Não foi possível encontrar nenhuma agência
      </Text>
    </Card>
  );
};
