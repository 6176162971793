import { Blackbox } from "components/basic/blackbox";
import Text from "components/basic/text";
import FormProvider from "components/form/form-store";
import { CardWithSections } from "components/basic/card/card";
import { DataGridColumnData } from "components/basic/data-grid/data-grid-columns";
import { CardSection } from "components/basic/card/card-section";
import { cssKeyframes } from "styles/animations";
import { getFilterByDataType } from "components/composed/filters/context/filter-definitions";
import Icon from "components/basic/icon";
import {
  IconBookmark,
  IconBookmarkEdit,
  IconCopy,
  IconDeviceFloppy,
  IconExclamationMark,
  IconFilterPlus,
  IconQuestionMark,
} from "@tabler/icons-react";
import { useState } from "react";
import CardHeader from "components/basic/card/card-header";
import Base from "components/basic/base";
import Button from "components/basic/button";
import { appLayoutContext } from "layout/app/layout-context";

const typesConstants = {
  alert: { icon: <IconExclamationMark />, rightButton: "Ok" },
  save: { icon: <IconDeviceFloppy />, rightButton: "Salvar" },
  question: { icon: <IconBookmarkEdit />, rightButton: "Confirmar" },
};

export type AppPopupProps = {
  visible: boolean;
  type: "alert" | "save" | "question";
  title?: string;
  label?: string;
  onRight: () => void;
  onDiscard?: () => void;
};
const AppPopup: Component = () => {
  const { useStore } = appLayoutContext;
  const { visible, type, title, label, onRight, onDiscard } = useStore(
    (state) => state.appPopupProps
  );
  const updatePopupProps = useStore((state) => state.updatePopupProps);

  const closePopup = () => {
    updatePopupProps({
      visible: false,
      type: "alert",
      title: undefined,
      label: undefined,
      onRight: () => {},
      onDiscard: undefined,
    });
  };

  if (!visible) return <></>;

  return (
    <Blackbox
      onClick={(event) => {
        event.currentTarget === event.target && closePopup();
      }}
    >
      <CardWithSections
        size="small"
        sx={{
          display: "flex",
          flexFlow: "column",
          width: "480px",
          animation: `${cssKeyframes.pop} 0.2s backwards`,
          cursor: "default",
        }}
      >
        <CardHeader sx={{gap: 1}}>
          {typesConstants[type].icon}
          <Text
            sx={{
              fontWeight: "bold",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            {title}
          </Text>
        </CardHeader>

        <CardSection direction="vertical" sx={{ gap: 1 }}>
          <Text>{label}</Text>
        
        <Base
          sx={{ width: "100%", display: "flex", justifyContent: "end", gap: 1, paddingTop: 2 }}
        >
          {type !== "alert" && (
            <Button variant="outlined" onClick={() => closePopup()}>
              Cancelar
            </Button>
          )}
          {type === "save" && onDiscard && (
            <Button variant="outlined" onClick={onDiscard}>
              Descartar Alterações
            </Button>
          )}
          <Button onClick={() => {onRight(); closePopup();}}>{typesConstants[type].rightButton}</Button>
        </Base>
        </CardSection>
      </CardWithSections>
    </Blackbox>
  );
};

export default AppPopup;
