import { DataGridRow } from "components/basic/data-grid/row";
import { DataGridCell } from "components/basic/data-grid/cell/cell";
import { useDataGridStore } from "./data-grid-store";
import { CheckboxRaw } from "components/form/inputs/checkbox";
import IconButton from "../icon-button";
import Icon from "../icon";
import { IconDotsVertical } from "@tabler/icons-react";
import { FlexFill } from "../flex";
import { PopupContainer } from "components/composed/filters/popup";
import FilterPopup from "components/composed/filters/filter";
import { useDisclosure } from "@mantine/hooks";
import { DataGridColumnData } from "./data-grid-columns";

export const DataGridHeaderColumns: Component = () => {
  const columns = useDataGridStore((state) => state.columns);

  return (
    <DataGridRow
      sx={{
        background: "neutral.n1",
        fontWeight: 700,
        color: "text.main",
        // textTransform: "uppercase",
        "&:hover": { background: "neutral.n1" },
      }}
    >
      {Object.entries(columns).map(([columnId, columnData]) => {
        if (columnData.renderAs === "select")
          return (
            <HeaderSelectCell key={columnId} {...{ columnId, columnData }} />
          );

        return <HeaderCell key={columnId} {...{ columnId, columnData }} />;
      })}
    </DataGridRow>
  );
};

type HeaderCellProps = { columnId: string; columnData: DataGridColumnData };
export const HeaderCell: ComponentWithChildren<HeaderCellProps> = ({
  columnId,
  columnData,
}) => {
  const [
    filterPopupVisibility,
    { close: closeFilterPopup, toggle: toggleFilterPopup },
  ] = useDisclosure();

  return (
    <DataGridCell
      key={columnId}
      columnId={columnId}
      columnData={columnData}
      sx={{
        // position: "relative",
        gap: 1,
        paddingRight: 1,
        //"& .data-grid-options": { opacity: 0 },
        // "&:hover .data-grid-options": { opacity: 1 },
        overflow: "visible",
        ...columnData.sx,
      }}
    >
      {columnData.label ?? columnId}
      <FlexFill />
      {columnData.label != "" && ( //TODO ver com o derick uma melhor forma
        <PopupContainer>
          <IconButton
            className="data-grid-options"
            size="micro"
            variant="ghost-text"
            color="text.main"
            sx={{ "&:hover": { background: "neutral.n2" } }}
            onClick={toggleFilterPopup}
          >
            <Icon as={IconDotsVertical} size="micro" />
          </IconButton>

          <FilterPopup
            columnId={columnId}
            dataType={columnData.dataType}
            label={columnData.label}
            onClose={closeFilterPopup}
            visible={filterPopupVisibility}
          />
        </PopupContainer>
      )}
    </DataGridCell>
  );
};

type HeaderSelectCellProps = { columnId: string; columnData: any };
const HeaderSelectCell: Component<HeaderSelectCellProps> = ({
  columnId,
  columnData,
}) => {
  const rows = useDataGridStore((state) => state.rows);
  const selectedRows = useDataGridStore((state) => state.selectedRows);
  const selectAllRows = useDataGridStore((state) => state.selectAllRows);
  const clearSelectedRows = useDataGridStore(
    (state) => state.clearSelectedRows
  );

  const getRowId = useDataGridStore((state) => state.getRowId);

  const isSelected = rows.every((row) => selectedRows.has(getRowId(row)));

  return (
    <DataGridCell {...{ key: columnId, columnId, columnData }}>
      <CheckboxRaw
        name="__select_all__"
        value={isSelected}
        onToggle={(value) => {
          if (isSelected) clearSelectedRows();
          else selectAllRows();
        }}
      />
    </DataGridCell>
  );
};
