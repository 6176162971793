import Form from "components/form/form-store";
import Button from "components/basic/button";
import { FlexColumn, FlexRow } from "components/basic/flex";
import Base from "components/basic/base";
import Text from "components/basic/text";
import { BiCheck } from "react-icons/bi";
import Separator from "components/basic/separator";
import Card from "components/basic/card/card";

import Image from "components/basic/image";
import { cssKeyframes } from "styles/animations";
import CustomRadio from "components/form/primitives/custom-radio";
import { useStepsContext } from "components/steps-controller/steps-context";
import {
  IconBuilding,
  IconBuildingCommunity,
  IconHome,
  IconStar,
} from "@tabler/icons-react";
import FormSubmitBehavior from "components/form/form-submit";
import { useStoreSetters } from "context/fast-context";
import { InputMessages } from "components/form/primitives/input-message";
import { useEffect, useState } from "react";
import { AdministrationService } from "services/requests";
import { Plan } from "services/requests/models/SubscriptionPlan";
import PriceCard from "pages/unauthenticated/landing/pricing/price-card";
import { LoadingPage } from "pages/other/loading-page";
import { globalErrorContext } from "context/error-context";
import { appLayoutContext } from "layout/app/layout-context";
import { useNavigate } from "react-router";

const discounts = {
  monthly: 0,
  quarterly: 5,
  annual: 15,
};
const paymentPeriod = "monthly";

type PlanName = "BÁSICO" | "DESBRAVADOR" | "PROFISSIONAL" | "PREMIUM";
const plansIcons = { //TODO trocar keys para plan id
  "BÁSICO": IconHome,
  "DESBRAVADOR": IconBuilding,
  "PROFISSIONAL": IconBuildingCommunity,
  "PREMIUM": IconStar,
}

type PlanInputType = {
  plan: "basic" | "explorer" | "professional" | "premium";
};
type PlansProps = {
  previousStepId: string;
  nextStepId: string;
};
const RegisterPlans: React.FC<PlansProps> = ({
  previousStepId,
  nextStepId,
}) => {
  const navigate = useNavigate();

  const stepsContext = useStepsContext();
  const { setCurrentStepId } = stepsContext;

  const { useStore: useLayoutContext } = appLayoutContext;
  const { updatePopupProps } = useLayoutContext((state) => state);

  const { useStore: errorUseStore } = globalErrorContext;
  const { setGlobalError } = errorUseStore((state) => state);

  const { updateValues } = useStoreSetters();

  const [plans, setPlans] = useState<Array<Plan>>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    AdministrationService.getSubscriptionPlan()
      .then((plan) => {
        setPlans(plan.message.plans);
      })
      .catch((error) => {
        setGlobalError(error, navigate, updatePopupProps);
        
      })
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) return <LoadingPage />
  return (
    <FlexColumn sx={{ gap: 2, width: "100%" }}>
      <FlexRow
        sx={{
          justifyContent: "space-evenly",
          gap: 1,
          alignItems: "center",
          width: "100%",
        }}
      >
        {plans?.map((plan) => (
          <CustomRadio name="plan" value={plan.name /*TODO value={plan.id} */} >
           {({ selected }) => (
            <PriceCard
              href={`http://localhost:5000/oauth2/login?period=${paymentPeriod}&plan=basic`}
              icon={plansIcons[plan.name as PlanName] /*TODO plansIcons[plan.id] */}
              title={plan.name}
              price={plan.price_per_month}
              discount={discounts[paymentPeriod]}
              features={[
                { label: "Usuários", quantity: plan.users },
                { label: "Imóveis", quantity: plan.properties },
                { label: "Contratos", quantity: "infinite" /*TODO falar com Jorge */},
                { label: "Gestão de locação", check: plan.lease_management },
                { label: "Gestão de venda", check: plan.sales_management },
                { label: "Preço por usuário adicional", value: plan.additional_user_price },
                { label: "Preço por imóvel adicional", value: plan.additional_property_price },
              ]}
              backgroundVariant={1}
              selected={selected}
            />
          )}
        </CustomRadio>
        ))}
      </FlexRow>
      <InputMessages name="plan" />
      <FlexRow sx={{ justifyContent: "center", gap: 2, width: "100%" }}>
        {/* <Button onClick={() => setCurrentStepId(previousStepId)} sx={{width: "50%"}}>Voltar</Button> */}
        <FormSubmitBehavior
          onSubmit={(state) => onSubmitForm(state.formValues as PlanInputType)}
        >
          <Button sx={{ width: "100%" }}>Avançar</Button>
        </FormSubmitBehavior>
      </FlexRow>
    </FlexColumn>
  );

  function onSubmitForm(formValues: PlanInputType) {
    updateValues(formValues);
    setCurrentStepId(nextStepId);
  }
};

type PlanCardProps = {
  title: string;
  price: number;
  attributes: string[];
  iconSrc: string;
  selected: boolean;
};
const PlanCard: ComponentWithChildren<PlanCardProps> = ({
  title,
  price,
  attributes,
  iconSrc,
  selected,
}) => {
  return (
    <Card
      size="unset"
      addOutline
      sx={{
        flexDirection: "column",
        alignItems: "flex-start",
        cursor: "pointer",
        boxShadow: 10,
        borderColor: "primary.main",
        flexGrow: 1,
        borderRadius: 2,
        transition: "all 0.2s",
        ...(selected && { animation: `${cssKeyframes.pop} 0.3s backwards` }),
        "&:hover": { transform: "scale(1.02)" },
      }}
    >
      <FlexColumn
        sx={{
          background: selected ? "primary.main" : "neutral.n3",
          width: "100%",
          p: 1,
          pb: 3,
          gap: 1,
        }}
      >
        <Text
          sx={{ color: "primary.contrastText", textTransform: "uppercase" }}
        >
          {title}
        </Text>
        {/* <Icon color={"white"} sx={{ maxHeight: "50px", alignSelf: "center" }} /> */}
        <Image src={iconSrc} sx={{ alignSelf: "center", width: "70px" }} />
      </FlexColumn>
      <Separator direction="col" shading="dark" sx={{ mt: "-1px" }} />
      <FlexColumn sx={{ width: "100%", p: 1, gap: 1, color: "text.main" }}>
        <Text
          sx={{
            color: "inherit",
            alignSelf: "center",
            fontWeight: "bold",
          }}
        >
          <Text sx={{ fontSize: 2 }}>R$</Text>
          <Text sx={{ fontSize: 5 }}>{Math.floor(price)}</Text>
          <Text sx={{ fontSize: 0 }}>
            {price.toFixed(2).replaceAll(/\d+\./g, "")}
          </Text>
        </Text>
        <Separator direction="col" shading="dark" />
        <Text sx={{ color: "inherit", alignSelf: "center", fontSize: 0 }}>
          Gestão Completa
        </Text>
        <Separator direction="col" shading="dark" />
        {attributes.map((attribute) => {
          return (
            <Text key={attribute} sx={{ color: "inherit", fontSize: 0 }}>
              <Base as={BiCheck} sx={{ color: "primary.main" }} />
              {attribute}
            </Text>
          );
        })}
      </FlexColumn>
    </Card>
  );
};

export default RegisterPlans;
