import { CardWithSections } from "components/basic/card/card";
import CardHeader from "components/basic/card/card-header";
import { FlexColumn, FlexRow } from "components/basic/flex";
import Text from "components/basic/text";
import BreadCrumb from "components/composed/breadcrumb";
import {
  RealStateCard,
  RealStates,
} from "../manage-agencies/manage-agencies-cards";
import { agencyContext } from "./agencies-context";
import { CardSection } from "components/basic/card/card-section";
import { withAuthenticationRequired } from "react-oidc-context";
import { DefaultIcon } from "components/basic/icon-default";
import { appLayoutContext } from "layout/app/layout-context";
import { useEffect } from "react";
import { SideMenuLink } from "layout/app/side-menu/side-menu-link";
import { useLoading } from "context/loading-context";

const OverviewPage = () => {
  
  const { useStore } = appLayoutContext;
  const updateNavigationBar = useStore((state) => state.updateNavigationBar);
  
  const { useStore: useAgencyStore } = agencyContext;
  const agency = useAgencyStore((state) => state.agency);
  const agency_first_letter = agency.name ? agency.name[0] : "";

  useEffect(() => {
    // const overviewPath = `/app/agencies/${agency_id}`;
    // const overview_navigate = (
    //   <SideMenuLink label={""} linkTo={overviewPath} />
    // );
    updateNavigationBar(null, 1);
  }, []);

  return (
    <>
      <FlexColumn>
        <Text variant="h3">Overview</Text>
        <BreadCrumb />
      </FlexColumn>

      <FlexRow sx={{ gap: 2, flexWrap: "wrap" }}>
        <CardWithSections size="micro" sx={{ flexGrow: 1, p: 1 }}>
          <CardHeader>
            <FlexRow sx={{ gap: 1 }}>
              <DefaultIcon text={agency_first_letter} />
              <Text
                sx={{
                  fontSize: 25,
                  fontWeight: 600,
                  whiteSpace: "nowrap",
                  marginBlock: "auto",
                }}
              >
                {agency.name}
              </Text>
            </FlexRow>
          </CardHeader>

          <CardSection>
            <FlexColumn sx={{ gap: 1 }}>
              <FlexRow>
                <Text variant="h6" sx={{ marginBlock: "auto" }}>
                  ID:
                </Text>
                <Text sx={{ px: 1 }}>{agency.id}</Text>
              </FlexRow>

              <FlexRow>
                <Text variant="h6" sx={{ marginBlock: "auto" }}>
                  Status:
                </Text>
                <Text sx={{ px: 1 }}>Ativo</Text>
                {/* TODO trocar para agency.status */}
              </FlexRow>

              <Text variant="h6">Descrição</Text>
              <Text sx={{ px: 2 }}>{agency.description}</Text>
            </FlexColumn>
          </CardSection>
        </CardWithSections>

        <CardWithSections
          size="micro"
          sx={{ flexGrow: 1, p: 1, width: "100px" }}
        >
          <CardHeader>
            <FlexRow sx={{ gap: 1 }}>
              <Text
                sx={{
                  fontSize: 18,
                  fontWeight: 600,
                  whiteSpace: "nowrap",
                  marginBlock: "auto",
                }}
              >
                Members
              </Text>
            </FlexRow>
          </CardHeader>

          <CardSection></CardSection>
        </CardWithSections>
      </FlexRow>
    </>
  );
};

export default withAuthenticationRequired(OverviewPage);
