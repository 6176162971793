import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

const DEFAULT_PAGE_SIZE = String(10);

export type PaginationGetParams = {
  page: string;
  limit: string;
};

type SetPaginationGetParams = (params: Partial<PaginationGetParams>) => void;

export function usePagination(): [PaginationGetParams, SetPaginationGetParams] {
  const [searchParams, setSearchParams] = useSearchParams();

  const pagination = useMemo(() => ({
    page: searchParams.get("page") ?? "1",
    limit: searchParams.get("pageSize") ?? DEFAULT_PAGE_SIZE,
  }), [searchParams]);

  const setPagination: SetPaginationGetParams = (newPagination) => {
    setSearchParams((previous) => ({ ...previous, ...newPagination }));
  };

  return [pagination, setPagination];
}
