import { FlexFill, FlexRow } from "components/basic/flex";
import { BiBell, BiExpand, BiMenu, BiUser } from "react-icons/bi";
import { Dispatch, SetStateAction } from "react";
import Button, { ButtonLink } from "components/basic/button";
import Icon from "components/basic/icon";
import Image from "components/basic/image";
import Separator from "components/basic/separator";
import IconButton from "components/basic/icon-button";
import { HEADER_HEIGHT } from "../side-menu/layout-constants";
import { appLayoutContext } from "../layout-context";
import Text from "components/basic/text";
import { SideMenuLink } from "../side-menu/side-menu-link";
import { DefaultIcon } from "components/basic/icon-default";
import React from "react";

type HeaderProps = { setMenuVisibility: Dispatch<SetStateAction<boolean>> };
const Header: Component<HeaderProps> = ({ setMenuVisibility, sx }) => {
  const { useStore } = appLayoutContext;
  const navigationBar = useStore((state) => state.navigationBar);

  return (
    <FlexRow
      sx={{
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        justifyItems: "center",
        position: "sticky",
        top: 0,
        width: "100%",
        height: `${HEADER_HEIGHT}px`,
        background: "background.paper",
        borderStyle: "solid",
        borderWidth: "0 0 1px 0",
        borderColor: "neutral.n2",
        zIndex: 3,
        p: 2,
        gap: 2,
        flexWrap: "nowrap",
        ...sx,
      }}
    >
      <ButtonLink
        href="/app/agencies"
        variant="ghost"
        sx={{ p: 1, height: "100%" }}
      >
        <Image src="/images/logo/logo-color.svg" sx={{ height: "100%" }} />
      </ButtonLink>

      <Separator direction="row" />

      <FlexRow>
        {navigationBar.length > 0 &&
          navigationBar
            .filter((item) => item !== null)
            .map((item, index, filteredArray) => (
              <React.Fragment key={index}>
                {item}
                {index < filteredArray.length - 1 && (
                  <Text sx={{ fontSize: "26px", color: "text.faded" }}>/</Text>
                )}
              </React.Fragment>
            ))}
      </FlexRow>

      <FlexFill />

      <IconButton variant="soft">
        <Icon as={BiBell} sx={{ fontSize: 3 }} />
        {/* Notificações */}
      </IconButton>

      <IconButton variant="soft">
        <Icon as={BiUser} sx={{ fontSize: 3 }} />
        {/* Usuário */}
      </IconButton>

      <IconButton variant="soft">
        <Icon as={BiExpand} sx={{ fontSize: 3 }} />
        {/* Fullscreen */}
      </IconButton>
    </FlexRow>
  );
};
export default Header;
