import Base from "components/basic/base";
import TextField from "components/form/custom-fields/text-field";
import {
  useFormStoreFieldValue,
  useFormStoreSetters,
} from "components/form/form-store";
import FormSubmitBehavior from "components/form/form-submit";
import LoadingAdornment from "components/form/primitives/input-adornment/loading";
import { useStepsContext } from "components/steps-controller/steps-context";
import { useStoreSettersProperty } from "../context";
import { useState } from "react";
import { cepMask, squareMetersMask } from "utils/input-masks";
import { numeroMask } from "./address";
import Button from "components/basic/button";
import DropdownField from "components/form/fields/dropdown-field";
import { FlexRow } from "components/basic/flex";

type PropertyProps = {
  nextStepId: string;
  previousStepId: string;
};
const PropertyForm: React.FC<PropertyProps> = ({
  nextStepId,
  previousStepId,
}) => {
  const stepsContext = useStepsContext();
  const { setCurrentStepId } = stepsContext;

  const { updateValues: updateContextValues } = useStoreSettersProperty();

  const categoryValue = useFormStoreFieldValue("category");
  const houseCategorys = ["Casa", "Apartamento"];

  return (
    <>
      <Base
        sx={{
          display: "grid",
          gridTemplateColumns:
            "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
          gap: 1,
          "@media(sm-)": {
            background: "red",
          },
          alignItems: "start",
          width: "700px",
        }}
      >
        <TextField
          name="property_title"
          label="Título do Imóvel"
          sx={{ gridColumn: "span 12" }}
        />
        <DropdownField
          name="category"
          label="Categoria"
          options={["Casa", "Apartamento", "Terreno"]}	
          sx={{ gridColumn: "span 12" }}
        />
        {houseCategorys.includes(categoryValue) && (
          <>
            <DropdownField
              name="suites"
              label="Suítes"
              options={["0", "1", "2", "3", "4"]}
              sx={{ gridColumn: "span 3" }}
            />
            <DropdownField
              name="parking_spots"
              label="Vagas"
              options={["0", "1", "2", "3", "4"]}
              sx={{ gridColumn: "span 3" }}
            />
            <DropdownField
              name="bedrooms"
              label="Quartos"
              options={["0", "1", "2", "3", "4"]}
              sx={{ gridColumn: "span 3" }}
            />
            <DropdownField
              name="bathrooms"
              label="Banheiros"
              options={["0", "1", "2", "3", "4"]}
              sx={{ gridColumn: "span 3" }}
            />
          </>
        )}
        <TextField
          name="constructed_area"
          label="Área Construída"
          mask={squareMetersMask}
          sx={{ gridColumn: "span 6" }}
        />
        <TextField
          name="total_area"
          label="Área Total"
          mask={squareMetersMask}
          sx={{ gridColumn: "span 6" }}
        />
      </Base>
      <Base
        sx={{ width: "100%", display: "flex", justifyContent: "end", gap: 1 }}
      >
        <Button onClick={() => setCurrentStepId(previousStepId)}>Voltar</Button>
        <FormSubmitBehavior
          onSubmit={(state) => {
            onSubmitForm(state.formValues);
          }}
        >
          <Button>Próximo</Button>
        </FormSubmitBehavior>
      </Base>
    </>
  );

  async function onSubmitForm(formValues: Record<string, any>) {
    updateContextValues(formValues);
    setCurrentStepId(nextStepId);
  }
};

export default PropertyForm;
