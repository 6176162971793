import Button from "components/basic/button";
import Card from "components/basic/card/card";
import Hr from "components/basic/hr";
import TextField from "components/form/custom-fields/text-field";
import Form from "components/form/form-store";
import Base from "components/basic/base";
import { FlexColumn } from "components/basic/flex";
import Text from "components/basic/text";
import {
  ccvMask,
  creditCardNumberMask,
  dateMonthYearMask,
} from "utils/input-masks";
import { useNavigate } from "react-router";
import { useStepsContext } from "components/steps-controller/steps-context";
import FormSubmitBehavior from "components/form/form-submit";
import { useStoreSetters } from "context/fast-context";
import { paymentFormContext } from "../../context";
import { AxiosError } from "axios";
import { useState } from "react";
import { LoadingPage } from "pages/other/loading-page";
import { globalErrorContext, globalErrorType } from "context/error-context";
import { appLayoutContext } from "layout/app/layout-context";

type PaymentProps = {
  previousStepId: string;
};
const RegisterPayment: React.FC<PaymentProps> = ({ previousStepId }) => {
  const navigate = useNavigate();

  const { useStore: useLayoutContext } = appLayoutContext;
  const { updatePopupProps } = useLayoutContext((state) => state);

  const { useStore: errorUseStore } = globalErrorContext;
  const { setGlobalError } = errorUseStore((state) => state);

  const stepsContext = useStepsContext();
  const { setCurrentStepId } = stepsContext;

  const { updateValues: updateContextValues } = useStoreSetters();
  const { useStore } = paymentFormContext;
  const values = useStore((state) => state.values);

  const [isLoading, setIsLoading] = useState(false);
  if (isLoading) return <LoadingPage />;

  return (
    <Base
      sx={{
        flexGrow: 2,
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: 1,
        "@media(sm-)": {
          background: "red",
        },
        width: "100%",
      }}
    >
      {/* <CreditCard sx={{ alignSelf: "center", minHeight: 150 }} /> */}
      <FlexColumn
        sx={{
          gap: 1,
          gridColumn: "span 1",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
        }}
      >
        <TextField
          name="card_number"
          label="Número do Cartão"
          placeholder="0000 0000 0000 0000"
          sx={{ gridColumn: "span 2" }}
          mask={creditCardNumberMask}
        />
        <TextField
          name="card_name"
          label="Nome do Titular"
          sx={{ gridColumn: "span 2" }}
          placeholder="Nome impresso no cartão"
        />
        <TextField
          name="card_expire"
          label="Data de validade"
          placeholder="MM/AA"
          sx={{ gridColumn: "span 1" }}
          mask={dateMonthYearMask}
        />
        <TextField
          name="card_verification_code"
          label="CVV"
          sx={{ gridColumn: "span 1" }}
          mask={ccvMask}
        />
      </FlexColumn>
      <Summary />
      <Button
        onClick={() => setCurrentStepId(previousStepId)}
        sx={{ gridColumn: "span 1" }}
      >
        Voltar
      </Button>
      <FormSubmitBehavior onSubmit={(state) => onSubmitForm(state.formValues)}>
        <Button sx={{ gridColumn: "span 1" }}>Finalizar</Button>
      </FormSubmitBehavior>
    </Base>
  );

  function onSubmitForm(formValues: Record<string, string>) {
    const payment_datas = {
      ...values,
      ...formValues,
    };
    try {
      updateContextValues(formValues);
      setIsLoading(true);
      const response = { status_code: 200 }; //TODO adicionar payment request
      if (response) {
        navigate("/application/agencies");
      }
    } catch (error) {
      setGlobalError(error as globalErrorType, navigate, updatePopupProps);
    } finally {
      setIsLoading(false);
    }
  }
};

export default RegisterPayment;

const Summary = () => {
  return (
    <Card
      variant="invert"
      size="small"
      addOutline
      sx={{
        borderRadius: 2,
        flexGrow: 1,
        gridColumn: "span 1",
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        fontSize: "0.8rem",
        gap: 1,
        fontFamily: "monospace",
        background: "rgba(0,0,0,0.02)",
      }}
    >
      <Text
        sx={{
          gridColumn: "span 2",
          textAlign: "center",
          fontWeight: "bold",
          fontSize: "1rem",
        }}
      >
        Resumo
      </Text>
      <Text>Plano Básico</Text>
      <Text sx={{ textAlign: "right" }}>R$99,99/mês</Text>
      <Text>Recorrência</Text>
      <Text sx={{ textAlign: "right" }}>Anual</Text>
      <Text>Desconto</Text>
      <Text sx={{ textAlign: "right" }}>-12%</Text>
      <Hr sx={{ gridColumn: "span 2" }} />
      <Text>Total</Text>
      <Text sx={{ textAlign: "right" }}>R$999,99</Text>
    </Card>
  );
};
