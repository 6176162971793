import createLocalZustand from "context/local-zustand";
import { AppPopupProps } from "layout/app/app-popup";
import { produce } from "immer";
import { HandleChangeValues } from "./fast-context";

export type globalErrorType = Record<string, any> | null;

interface globalErrorContextType {
  globalError: globalErrorType;
  set: HandleChangeStore;
  get: () => globalErrorContextType;
  setGlobalError: (
    error: globalErrorType,
    navigate?: (path: string) => void,
    setPopup?: HandleChangeValues,
  ) => void;
  clearGlobalError: () => void;
}

export type HandleChangeStore = (
  partial:
    | globalErrorContextType
    | Partial<globalErrorContextType>
    | ((
        state: globalErrorContextType
      ) => globalErrorContextType | Partial<globalErrorContextType>),
  replace?: boolean | undefined
) => void;

export const globalErrorContext = createLocalZustand<globalErrorContextType>(
  (initializer) => (set, get) => ({
    globalError: null,
    set: set,
    get: get,
    setGlobalError: (error, navigate, setPopup) => {
      set(
        produce((state) => {
          state.globalError = JSON.parse(JSON.stringify(error));
        })
      );

      console.error(error);
      const status_code = error?.code || error?.status;
      if (status_code === "ERR_NETWORK" && setPopup) {
        setPopup({
          visible: true,
          title: "Network Error",
          label:
            "Verifique sua conexão com a internet, caso o erro persista entre em contato.",
          type: "alert",
          onRight: async () => {},
        });
      } else if (navigate) navigate('/error');
    },
    clearGlobalError: () => {
      set(
        produce((state) => {
          state.globalError = null;
        })
      );
    },
  })
);
