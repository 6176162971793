import Base from "components/basic/base";
import Card from "components/basic/card/card";
import { FlexColumn } from "components/basic/flex";
import Image from "components/basic/image";
import Text from "components/basic/text";
import { globalErrorContext } from "context/error-context";

const ErrorPage: Component = () => {
  const { useStore: errorUseStore } = globalErrorContext;
  const { globalError } = errorUseStore((state) => state);
  console.log(globalError);
  const status_code = globalError?.code || globalError?.status;
  const status_text = globalError?.statusText;

  const getMessageError = () => {
    switch (status_code) {
      case "ERR_NETWORK":
        return "Verifique sua conexão com a internet, caso o erro persista entre em contato.";
      default:
        return "Tivemos um erro inesperado. Tente novamente, caso o erro persista entre em contato.";
    }
  };

  return (
    <Base
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#eef2f6", //TODO remover
      }}
    >
      <Card
        direction="vertical"
        sx={{
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          width: "500px",
          gap: 3,
        }}
      >
        <Image src="/images/logo/logo-min-color.png" sx={{ height: 100 }} />
        <FlexColumn>
          <Text variant="h2">{status_text ? status_text : "Error"} {status_code}</Text>
          {status_code && <Text>{getMessageError()}</Text>}
        </FlexColumn>
      </Card>
    </Base>
  );
};

export default ErrorPage;
