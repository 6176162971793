import TextField from "components/form/custom-fields/text-field";
import { useFormStoreSetters } from "components/form/form-store";
import Button from "components/basic/button";
import Base from "components/basic/base";
import { cepMask } from "utils/input-masks";
import { useState } from "react";
import getCepData, { CepData } from "services/get-cep-data";
import LoadingAdornment from "components/form/primitives/input-adornment/loading";
import { useStepsContext } from "components/steps-controller/steps-context";
import FormSubmitBehavior from "components/form/form-submit";
import { managePropertyFormContext, useStoreSettersProperty } from "../context";

type MaskFunction = (value: string) => {
  unmasked: string;
  masked: string;
};

export const numeroMask: MaskFunction = (value) => {
  const unmasked = value.replace(/\D+/g, "");
  const masked = `N°${unmasked}`;
  return { unmasked, masked };
};

type AddressProps = {
  nextStepId: string;
  previousStepId: string;
};
const AddressForm: React.FC<AddressProps> = ({
  previousStepId,
  nextStepId,
}) => {
  const stepsContext = useStepsContext();
  const { setCurrentStepId } = stepsContext;

  const { updateValues: updateContextValues } = useStoreSettersProperty();
  const { useStore } = managePropertyFormContext;
  const values = useStore((state) => state.values);

  const [cepLoading, setCepLoading] = useState(false);
  const { updateValues } = useFormStoreSetters();
  const [isValidCep, setIsValidCep] = useState(true);

  return (
    <>
      <Base
        sx={{
          display: "grid",
          gridTemplateColumns:
            "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
          gap: 1,
          "@media(sm-)": {
            background: "red",
          },
          alignItems: "start",
          width: "700px",
        }}
      >
        <TextField
          name="postal_code"
          label="CEP"
          mask={cepMask}
          placeholder="0000-000"
          sx={{ gridColumn: "span 2" }}
          type="text"
          onFieldChange={({ value }) => onCEPFilled(value)}
          rightAdornment={cepLoading && <LoadingAdornment />}
          disabled={cepLoading}
          validationMoment="blur"
          validation={() => (!isValidCep ? "Digite um CEP válido" : undefined)}
        />
        <TextField
          name="street"
          label="Logradouro"
          sx={{ gridColumn: "span 10" }}
          rightAdornment={cepLoading && <LoadingAdornment />}
          disabled={cepLoading}
        />
        <TextField
          name="number"
          label="Número"
          sx={{ gridColumn: "span 2" }}
          mask={numeroMask}
        />
        <TextField
          name="complement"
          label="Complemento (opcional)"
          sx={{ gridColumn: "span 10" }}
        />
        <TextField
          name="neighborhood"
          label="Bairro"
          sx={{ gridColumn: "span 4" }}
          rightAdornment={cepLoading && <LoadingAdornment />}
          disabled={cepLoading}
        />
        <TextField
          name="city"
          label="Cidade"
          sx={{ gridColumn: "span 4" }}
          rightAdornment={cepLoading && <LoadingAdornment />}
          disabled={cepLoading}
        />
        <TextField
          name="state"
          label="Estado"
          sx={{ gridColumn: "span 4" }}
          rightAdornment={cepLoading && <LoadingAdornment />}
          disabled={cepLoading}
        />
      </Base>
      <Base
        sx={{ width: "100%", display: "flex", justifyContent: "end", gap: 1 }}
      >
        <FormSubmitBehavior
          onSubmit={(state) => {
            const { cep, ...property_datas } = state.formValues;
            onSubmitForm(property_datas);
          }}
        >
          <Button>Próximo</Button>
        </FormSubmitBehavior>
      </Base>
    </>
  );

  async function onSubmitForm(formValues: Record<string, any>) {
    updateContextValues({...formValues,
      property_title:
      values.property_title ? values.property_title : formValues.street,
    });
    setCurrentStepId(nextStepId);
  }

  async function onCEPFilled(value: string) {
    if (value.length === 8) {
      try {
        setCepLoading(true);
        const cepData = await getCepData(value);
        const { logradouro, bairro, localidade, uf, cep } =
          cepData?.data as CepData;
        const newValues = {
          postal_code: cep,
          street: logradouro,
          neighborhood: bairro,
          city: localidade,
          state: uf,
        };
        updateValues(newValues);
        setIsValidCep(true);
      } catch (error) {
        setIsValidCep(false);
        console.error(error);
      } finally {
        setCepLoading(false);
      }
    }
  }
};

export default AddressForm;
