import Card from "components/basic/card/card";
import { useDataGridStore } from "components/basic/data-grid/data-grid-store";
import { SearchField } from "components/form/custom-fields/search-field";
import FormProvider from "components/form/form-store";
import Button from "components/basic/button";
import IconButton from "components/basic/icon-button";
import {
  IconEdit,
  IconFaceMask,
  IconFilter,
  IconMask,
  IconMasksTheater,
  IconPlus,
  IconSettings,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import Icon from "components/basic/icon";
import { backendPropertiesCrud } from "services/housien/properties";
import { toast } from "react-toastify";
import { useCrudStore } from "./crud-store";
import { DataGridRowData } from "components/basic/data-grid/data-grid-columns";
import { useNavigate, useParams } from "react-router-dom";
import { PropertiesService } from "services/requests";
import { appLayoutContext } from "layout/app/layout-context";
import { globalErrorContext, globalErrorType } from "context/error-context";

type ManagePropertiesHeaderProps = {};
export const ManagePropertiesHeader: Component<
  ManagePropertiesHeaderProps
> = ({}) => {
  // CRUD
  const refreshFetch = useCrudStore((state) => state.refreshFetch);
  const isFormVisible = useCrudStore((state) => state.isFormVisible);
  const setIsFormVisible = useCrudStore((state) => state.setIsFormVisible);
  const setIstableExpanded = useCrudStore((state) => state.setIsTableVisible);
  const setEditingEntity = useCrudStore((state) => state.setEditingEntity);
  // DataGrid
  const getRowId = useDataGridStore((state) => state.getRowId);
  const rows = useDataGridStore((state) => state.rows);
  const selectedRows = useDataGridStore((state) => state.selectedRows);
  const clearSelectedRows = useDataGridStore(
    (state) => state.clearSelectedRows
  );

  const navigate = useNavigate();

  const { useStore: errorUseStore } = globalErrorContext;
  const { setGlobalError } = errorUseStore((state) => state);

  const { useStore } = appLayoutContext;
  const updatePopupProps = useStore((state) => state.updatePopupProps);

  const { agency_id } = useParams();

  let selectedRowData: DataGridRowData;
  if (selectedRows.size === 1) {
    selectedRowData = rows.find((row) =>
      selectedRows.has(getRowId(row))
    ) as DataGridRowData;
  }

  const handleNewProperty = async () => {
    clearSelectedRows();
    setEditingEntity(null);
    setIstableExpanded(false);
    setIsFormVisible(true);
  };

  const handleDelete = async (id: string) => {
    try {
      if (agency_id) await backendPropertiesCrud(agency_id).exclude({ id });
      // await backendPropertiesCrud(agency_id).exclude({ id });
      refreshFetch();
    } catch (error) {
      setGlobalError(error as globalErrorType, navigate, updatePopupProps);
    }
  };

  const handleDeletes = async (ids: string[]) => {
    updatePopupProps({
      visible: true,
      title: "Excluir Propriedades",
      label: `Tem certeza que deseja excluir estas ${ids.length} propriedades? ${ids}`,
      type: "question",
      onRight: async () => {
        try {
          ids.forEach(async (id) => await handleDelete(id));
          toast.info("Records delete successfully");

          refreshFetch();
        } catch (error) {
          console.log(error);
          toast.error(
            "Could not delete records, please try again later. If the problem persist, call the support."
          );
        }
      },
    });
  };

  return (
    <Card size="micro" sx={{ width: "100%", gap: 1 }}>
      <FormProvider>
        {!isFormVisible && (
          <>
            <Button size="small" onClick={handleNewProperty} sx={{ gap: 1 }}>
              <Icon as={IconPlus} sx={{ width: "1.2rem" }} />
              New Property
            </Button>

            <Button size="small" variant="outlined" sx={{ gap: 1 }}>
              <Icon as={IconFilter} sx={{ width: "1.2rem" }} />
              Filters
            </Button>

            <SearchField sx={{ flexGrow: 1 }} />

            {/* <Button
              variant="outlined"
              sx={{ gap: 1 }}
              disabled={selectedRows.size !== 1}
              onClick={() => handleEdit()}
            >
              <Icon as={IconEdit} sx={{ width: "1.2rem" }} /> Editar
            </Button> */}

            <Button
              variant="outlined"
              color="error.main"
              sx={{ gap: 1 }}
              disabled={selectedRows.size < 1}
              onClick={() => handleDeletes([...selectedRows])}
            >
              <Icon as={IconTrash} sx={{ width: "1.2rem" }} /> Excluir (
              {selectedRows.size})
            </Button>

            <IconButton variant="outlined">
              <Icon as={IconSettings} />
            </IconButton>
          </>
        )}
      </FormProvider>
    </Card>
  );
};
