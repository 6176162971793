/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { SubscriptionPlan } from "../models/SubscriptionPlan";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class AdministrationService {
  /**
   * Get Plan Information
   * Returns the plan information.
   * @returns SubscriptionPlan Plan information retrieved successfully.
   * @throws ApiError
   */
  public static getSubscriptionPlan(): CancelablePromise<SubscriptionPlan> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/admnistration/subscription/plans",
    });
  }
}
