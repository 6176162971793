import { UserManager, WebStorageStateStore } from "oidc-client-ts";
import { ReactNode, useEffect, useState } from "react";
import { AuthProvider, useAuth as _useAuth } from "react-oidc-context";
import { verifyToken } from "services/auth/auth";
import { oidcService } from "services/oidc/oidc";
import { fastContext } from "./fast-context";
import { globalErrorContext } from "./error-context";
import { useNavigate } from "react-router";

export const {
  REACT_APP_AUTHORITY_URL = "",
  REACT_APP_CLIENT_ID = "",
  REACT_APP_REDIRECT_URI = "",
  REACT_APP_LINK_AUTHIEN = "",
} = process.env;

// OIDC configuration
const oidcConfiguration = {
  authority: REACT_APP_AUTHORITY_URL,
  client_id: REACT_APP_CLIENT_ID,
  redirect_uri: `${window.location.origin}/authentication/callback`,
  response_type: "code",
  scope: "openid",
  userStore: new WebStorageStateStore({
    prefix: "oidc:user",
    store: window.localStorage,
  }),
  stateStore: new WebStorageStateStore({
    prefix: "oidc:state",
    store: window.localStorage,
  }),
  loadUserInfo: true,
  automaticSilentRenew: true,
};

export const UserAuthManager = new UserManager(oidcConfiguration);

export const validateAuthContext = fastContext;

export const AuthenticationProvider: ComponentWithChildren = ({ children }) => {

  const validateToken = async () => {
    const user = await UserAuthManager.getUser();
    const access_token = user?.access_token || "";
    return await verifyToken(access_token);
  };
  useEffect(() => {
    validateToken()
      .then(async (result) => {
        if (!result) await UserAuthManager.removeUser();
      })
      .catch(async (err) => {
        await UserAuthManager.removeUser();
        await UserAuthManager.signinRedirect();
      });
  }, []);

  return (
    <AuthProvider {...oidcConfiguration}>
        {children}
    </AuthProvider>
  );
};

export const useAuth = _useAuth;

export type ProtectedRouteProps = {
  element: ReactNode;
};

export default AuthenticationProvider;
