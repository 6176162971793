import TextField from "components/form/custom-fields/text-field";
import Button from "components/basic/button";
import Base from "components/basic/base";
import { cnpjMask } from "utils/input-masks";
import { useStepsContext } from "components/steps-controller/steps-context";
import FormSubmitBehavior from "components/form/form-submit";
import { AgencyInput } from "services/requests";
import { useStoreSetters } from "context/fast-context";

type AgencyProps = {
  nextStepId: string;
};
const RegisterAgency: React.FC<AgencyProps> = ({ nextStepId }) => {
  const { updateValues } = useStoreSetters();

  const stepsContext = useStepsContext();
  const { setCurrentStepId } = stepsContext;

  return (
    <>
      <TextField sx={{ width: "100%" }} name="name" label="Nome comercial" />
      <TextField
        sx={{ width: "100%" }}
        name="cnpj"
        label="CNPJ"
        mask={cnpjMask}
        placeholder="00.000.000/0000-00"
      />

      <Base sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
        <FormSubmitBehavior
          onSubmit={(state) => {
            const { cep, ...agency_datas } = state.formValues;
            onSubmitForm(agency_datas as AgencyInput);
          }}
        >
          <Button>Próximo</Button>
        </FormSubmitBehavior>
      </Base>
    </>
  );

  function onSubmitForm(formValues: AgencyInput) {
    updateValues(formValues);
    setCurrentStepId(nextStepId);
  }
};

export default RegisterAgency;
