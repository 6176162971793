import Button from "components/basic/button";
import { FlexColumn } from "components/basic/flex";
import Image from "components/basic/image";
import Text from "components/basic/text";
import { useNavigate } from "react-router-dom"; 

type NotFoundPageProps = { redirect?: string };
const NotFoundPage: Component<NotFoundPageProps> = ({ redirect = "/" }) => {
  const navigate = useNavigate(); 
  
  return (
    <FlexColumn
      sx={{
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        height: "100%",
        width: "100%",
        flexGrow: 1,
        gap: 3,
      }}
    >
      <Image src="/images/logo/logo-min-color.png" sx={{ height: 100 }} />
      <FlexColumn>
        <Text variant="h1">404 Not Found</Text>
        <Text sx={{ color: "text.faded" }}>
          The page you're looking for doesn't exist.
        </Text>
      </FlexColumn>
      <Button onClick={() => navigate(redirect)} preventDefault>
        Go Home
      </Button>
    </FlexColumn>
  );
};

export default NotFoundPage;
