import createLocalZustand from "context/local-zustand";
import { LinkItems } from "./side-menu";
import { AppPopupProps } from "layout/app/app-popup";
import { produce } from "immer";

interface AppLayoutContextType {
  linkItems: LinkItems | any;
  appPopupProps: AppPopupProps;
  navigationBar: Array<JSX.Element | null>;
  set: HandleChangeStore;
  get: () => AppLayoutContextType;
  updateNavigationBar: (component: JSX.Element | null, index?: number) => void;
  updatePopupProps: HandleChangeValues;
}

export type HandleChangeStore = (
  partial:
    | AppLayoutContextType
    | Partial<AppLayoutContextType>
    | ((
        state: AppLayoutContextType
      ) => AppLayoutContextType | Partial<AppLayoutContextType>),
  replace?: boolean | undefined
) => void;

export type HandleChangeValues = (popupProps: Partial<AppPopupProps>) => void;

export const appLayoutContext = createLocalZustand<AppLayoutContextType>(
  (initializer) => (set, get) => ({
    linkItems: { ...initializer },
    navigationBar: [],
    appPopupProps: { visible: false, type: "alert", onRight: () => {} },
    set: set,
    get: get,
    updateNavigationBar: (component, index) => {
      set((state) => {
        const updatedNavigationBar = [...state.navigationBar];

        if (index !== undefined && index >= updatedNavigationBar.length) {
          const placeholders = Array(index - updatedNavigationBar.length).fill(
            null
          );
          updatedNavigationBar.push(...placeholders);
        }

        if (index !== undefined && index >= 0) {
          updatedNavigationBar.length = index + 1;
          updatedNavigationBar[index] = component;
        }

        return { ...state, navigationBar: updatedNavigationBar };
      });
    },
    updatePopupProps: (newValues) =>
      set((state) => {
        const { appPopupProps } = get();
        const updatedAppPopupProps = { ...appPopupProps, ...newValues };
        return { ...state, appPopupProps: updatedAppPopupProps };;
      }),
  })
);
